const config = {
  okta: {
    issuer: 'https://dev-465437.okta.com/oauth2/default',
    clientId: '0oa1bp7s6zCEiXWep4x7',
    devMode: true
  },
  api: {
    url: 'https://mgmt-at-sg1.ddt.telenor.io'
  }
}

export default config
